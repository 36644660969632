import { useEffect, useState } from 'react'
import {
  WrapFirstView,
  WrapFaq,
  WrapMessage,
  WrapMerit,
  WrapPlan,
  WrapExample,
  WrapFlow,
  WrapVoice,
  GoTop,
} from '~/components'
import * as s from '~/components/pages/wrap/wrap.module.scss'

export default function WrapPage() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <WrapFirstView />
      <div
        className={`
        ${s.wrap_main_wrapper}
        ${loaded ? s.fadeIn : ''}
      `}
      >
        <WrapMerit />
        <WrapPlan />
        <WrapExample />
        <WrapFlow />
        <WrapVoice />
        <WrapFaq />
        <WrapMessage />
        <GoTop />
      </div>
    </>
  )
}
